import axios from './axios';
import apiConfig from './api.config';

function http(options) {
    let name = options.name;
    let params = options.data;
    let method = options.method || 'get';
    return axios[method](apiConfig[name], method == 'get' ? {params: params} : params).then((data) => {
        if (options.success) {
            options.success.call(this, data)
        }

        return new Promise((resolve, reject) => {
            resolve(data)
        })
    });
}

export function httpAll() {
    
}

export default http