import React from "react";
import ReactDOM from "react-dom";
import { Router, Switch } from "react-router-dom";

//全局控制
import controls from "./controls";

// 路由管理
import history from "./history";
import { renderRoutes } from "./router/reactRouterConfig";
import routerConfig from "./router/config";

// 全局样式
import "./assets/scss/common.scss";

//初始化全局控制
controls.init(React);

ReactDOM.render(
  <Router history={history}>
    <Switch>{renderRoutes(routerConfig)}</Switch>
  </Router>,
  document.getElementById("root")
);
