import React from 'react';
import Loadable from 'react-loadable';
import { Spin, Skeleton } from 'antd';

//通用的过场组件
const loadingComponent = () => {
    let style= {
        width:'80%', 
        height: '80%', 
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    }
    return (
        <div style={style}>
            <Skeleton paragraph={{ rows: 4 }} />
        </div>
    )
}

//过场组件默认采用通用的，若传入了loading，则采用传入的过场组件
export default (loader, loading = loadingComponent) => {
    return Loadable({
        loader,
        loading
    });
}