let apis = {
    getHobbyList: '/manage/hobby/getPagedList', //获取手办列表

    createHobby: '/manage/hobby/create', //新增手办信息
    editHobby: '/manage/hobby/edit', //编辑手办信息

    getHobbyDetailInfo: '/manage/hobby/getDetailInfo', //查询手办详情
    deleteHobby: '/manage/hobby/del', //删除手办

    getHobbyRoleList: '/manage/hobbyRole/getPagedList', //获取手办角色列表
    createRole: '/manage/hobbyRole/create', //新增角色
    editRole: '/manage/hobbyRole/edit', //编辑角色
    deleteRole: '/manage/hobbyRole/del', //删除角色
    getHobbyRoleInfo: '/manage/hobbyRole/getInfo', //查询角色详情

    gethobbyFamilyList: '/manage/hobbyFamily/getPagedList', //获取阵营列表
    createHobbyFamily: '/manage/hobbyFamily/create', //新增阵营
    editHobbyFamily: '/manage/hobbyFamily/edit', //编辑阵营
    deleteHobbyFamily: '/manage/hobbyFamily/del', //删除阵营
    getHobbyFamilyInfo: '/manage/hobbyFamily/getInfo', //查询阵营详情

    getHobbySeries: '/manage/hobbySeries/getPagedList', //获取手办系列列表

    createHobbySeries: '/manage/hobbySeries/create', //新增手办系列
    editHobbySeries: '/manage/hobbySeries/edit', //编辑手办系列

    getSeriesDetail: '/manage/hobbySeries/getInfo', //查询系列详情
    deleteSeries: '/manage/hobbySeries/del', //删除系列

    getHobbyVendor: '/manage/hobbyVendor/getPagedList', //获取厂商列表
    addVendor: '/manage/hobbyVendor/create', //添加厂商
    editVendor: '/manage/hobbyVendor/edit', //编辑厂商
    deleteVendor: '/manage/hobbyVendor/del', //删除厂商
    getVendorDeatail: '/manage/hobbyVendor/getInfo', //厂商详情

    getBbsList: '/manage/bbs/getPagedList', //帖子列表
    getBbsDetail: '/manage/bbs/getInfo', //帖子详情
    BbsAudit: '/manage/bbs/audit', //帖子审核
    deleteBbs: 'manage/bbs/del', //删除帖子

    getUserList: '/manage/user/getPagedList',//获取用户列表
    doLogin: '/manage/passport/login', //登录

    getBannerList: '/manage/ad/getPagedList', //获取bannerList

    createBanner: '/manage/ad/create', //新增banner
    editBanner: '/manage/ad/edit', //编辑banner

    bannerPublish: '/manage/ad/publish', //上下架banner
    deleteBanner: '/manage/ad/del', //删除banner
    getBannerInfo: '/manage/ad/getInfo', //获取banner详情
    getAdviceList: '/manage/advice/getPagedList', //意见反馈列表

    hobbyShelve: '/manage/hobby/shelve', //手办上架
    hobbyUnShelve: '/manage/hobby/unShelve', //手办下架
    changePassword: '/manage/passport/changePwd', //修改密码
    logout: '/manage/account/logout', //退出
    editRoleShowClient: '/manage/hobbyRole/editShowClient', //角色是否上场还是下场

    getSupplementPageList: '/manage/hobby/getSupplementPageList', //获取补充手办列表数据
    supplementPass: '/manage/hobby/supplementPass', //手办审核通过
    supplementNoPass: '/manage/hobby/supplementNoPass', //手办审核不通过
    syncToHobby: '/manage/hobby/syncToHobby', //补充手办迁移
    getSupplementDetail: '/manage/hobby/getSupplementDetail', //获取补充手办详情数据
    editSupplement: '/manage/hobby/editSupplement', //补充手办编辑保存
    bbsUpdateOrCreate: '/manage/bbs/updateOrCreate', //添加帖子
    delSupplement: '/manage/hobby/delSupplement', //删除手办
    getManageList: '/manage/manage/getList', //获取管理员账号

    getHobbyTypeDict: '/manage/hobby/getTypeDict' //获取手办类别
}

export default apis;