import axios from 'axios';
// import { Indicator } from 'mint-ui';
// import 'mint-ui/lib/style.css';
import { stringify } from 'qs';
import {message} from 'antd'
import history from '../history'

let token = window.localStorage.getItem('token');
if (token) axios.defaults.headers['Authorization'] = token;
const service = axios.create({
  timeout: 5000
});

service.interceptors.request.use(
  config => {
    // Indicator.open({
    //   text: '加载中...',
    //   spinnerType: 'snake'
    // });

    const contentType = 'application/x-www-form-urlencoded; charset=utf-8';
    const methods = ['post', 'put', 'delete'];

    if (config.headers['Content-Type'] === contentType && methods.includes(config.method)) {
      config.data = stringify(config.data);
    }

    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  response => {
    // Indicator.close();
    const res = response.data;

    if (res.type == 'application/vnd.ms-excel') {
      let url = window.URL.createObjectURL(new Blob([res]));
      let fileName = response.headers['content-disposition'].split('filename=');
      return {
        url: url,
        fileName: fileName
      };
    }

    if (res.code == 1) {
      return res;
    } else {
      //token失效 用户名或者密码错误res.code === 100002  权限不正确
      //先处理这几种情况直接跳转login
      if (res.code === 401) {
        message.error('无权限')
        history.push('/login')
      } else {
        message.error(res.message || 'Error')
      }
      return Promise.resolve(res)
    } 
  },
  error => {
    // Indicator.close();
    message.error('Error')
    return Promise.resolve(res)
  }
);

export default service;
