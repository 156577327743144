//懒加载
import loadable from './util'

const Container = loadable(() => import('../components/layout/Container'));

//route
const Login = loadable(() => import('../views/login/index'));
const Error = loadable(() => import('../views/error/index'));
const users = loadable(() => import('../views/users/index'));

const figurineList = loadable(() => import('../views/figurine_list/index'));
const figurineEdit = loadable(() => import('../views/figurine_list/edit'));
const figurineAdd = loadable(() => import('../views/figurine_list/add'));

const figurineSupplyList = loadable(() => import('../views/figurine_supply/index'));
const figurineSupplyEdit = loadable(() => import('../views/figurine_supply/edit'));
// const figurineSupplyAdd = loadable(() => import('../views/figurine_supply/add'));

const figurineRole = loadable(() => import('../views/figurine_role/index'));
const roleAdd = loadable(() => import('../views/figurine_role/add'));
const roleEdit = loadable(() => import('../views/figurine_role/edit'));

const figurineSeries = loadable(() => import('../views/figurine_series/index'));
const seriesAdd = loadable(() => import('../views/figurine_series/add'));
const seriesEdit = loadable(() => import('../views/figurine_series/edit'));

const figurineVendor = loadable(() => import('../views/figurine_vendor/index'));
const vendorAdd = loadable(() => import('../views/figurine_vendor/add'));
const vendorEdit = loadable(() => import('../views/figurine_vendor/edit'));

const bbs = loadable(() => import('../views/bbs/index'));
const bbsEdit = loadable(() => import('../views/bbs/edit'));
const bbsAdd = loadable(() => import('../views/bbs/add'));

const bbsAssess = loadable(() => import('../views/bbsAssess/index'));
const bbsAssessEdit = loadable(() => import('../views/bbsAssess/edit'));
const bbsAssessAdd = loadable(() => import('../views/bbsAssess/add'));

const banners = loadable(() => import('../views/banners/index'));
const bannersAdd = loadable(() => import('../views/banners/add'));
const bannersEdit = loadable(() => import('../views/banners/edit'));

const group = loadable(() => import('../views/group/index'));
const groupAdd = loadable(() => import('../views/group/add'));
const groupEdit = loadable(() => import('../views/group/edit'));

const feedback = loadable(() => import('../views/feedback/index'));
const addPost = loadable(() => import('../views/addPost/index'));

const routerConfig = [
  { path: '/login', component: Login, exact: true },
  { path: '/', component: Login, exact: true },
  { path: '/404', component: Error, exact: true },
  {
    path: '/', component: Container, routes: [
      { path: '/users', component: users },

      { path: '/figurineList', component: figurineList },
      { path: '/figurineEdit', component: figurineEdit },
      { path: '/figurineAdd', component: figurineAdd },

      { path: '/figurineSupply', component: figurineSupplyList },
      { path: '/figurineSupplyEdit', component: figurineSupplyEdit },
      // { path: '/figurineSupplyAdd', component: figurineSupplyAdd },

      { path: '/figurineRoles', component: figurineRole },
      { path: '/roleAdd', component: roleAdd },
      { path: '/roleEdit', component: roleEdit },

      { path: '/figurineSeries', component: figurineSeries },
      { path: '/seriesAdd', component: seriesAdd },
      { path: '/seriesEdit', component: seriesEdit },

      { path: '/figurineVendor', component: figurineVendor },
      { path: '/vendorAdd', component: vendorAdd },
      { path: '/vendorEdit', component: vendorEdit },

      { path: '/bbs', component: bbs },
      { path: '/bbsEdit', component: bbsEdit },
      { path: '/bbsAdd', component: bbsAdd },

      { path: '/bbsAssess', component: bbsAssess },
      { path: '/bbsAssessEdit', component: bbsAssessEdit },
      { path: '/bbsAssessAdd', component: bbsAssessAdd },

      { path: '/banners', component: banners },
      { path: '/bannersEdit', component: bannersEdit },
      { path: '/bannersAdd', component: bannersAdd },

      { path: '/group', component: group },
      { path: '/groupAdd', component: groupAdd },
      { path: '/groupEdit', component: groupEdit },

      { path: '/feedback', component: feedback },
      { path: '/addPost', component: addPost}
    ]
  },

];

export default routerConfig